:root {
  --brand-angle: 12deg;

  --zIndex-snackbar: 1300;
  --zIndex-livechat: 1275;
  --zIndex-preview: 1250;
  --zIndex-modal: 1225;
  --zIndex-headerAbove: 1201;
  --zIndex-header: 1200;
  --zIndex-headerBelow: 1199;

  /** Options colours */
  --blu-050: #edfdff;
  --blu-100: #c7ecf3;
  --blu-200: #b9dae2;
  --blu-300: #a5c2ca;
  --blu-400: #8fa7af;
  --blu-500: #768990;
  --blu-600: #65747b;
  --blu-700: #556167;
  --blu-800: #454e52;
  --blu-900: #282d2f;
  --gre-050: #ebffeb;
  --gre-100: #8bfdac;
  --gre-200: #75eba8;
  --gre-300: #5ed29c;
  --gre-400: #49b68e;
  --gre-500: #37967a;
  --gre-600: #2a7f6c;
  --gre-700: #216a5e;
  --gre-800: #1b554e;
  --gre-900: #15312e;
  --ind-050: #f2edfc;
  --ind-100: #e5dcfa;
  --ind-200: #c8b4f4;
  --ind-300: #a888ed;
  --ind-400: #8b60e7;
  --ind-500: #6b34e0;
  --ind-600: #541ec8;
  --ind-700: #42189b;
  --ind-800: #311273;
  --ind-900: #1e0b47;
  --ora-050: #fff9f3;
  --ora-100: #ffdfc2;
  --ora-200: #ffc99a;
  --ora-300: #f3ae78;
  --ora-400: #d39669;
  --ora-500: #ae7b59;
  --ora-600: #94684d;
  --ora-700: #7c5741;
  --ora-800: #634636;
  --ora-900: #282d2f;
  --per-050: #faf9ff;
  --per-100: #e8e1ff;
  --per-200: #d8ceff;
  --per-300: #c0b7e4;
  --per-400: #a69ec5;
  --per-500: #8882a1;
  --per-600: #746f89;
  --per-700: #615d72;
  --per-800: #4d4a5a;
  --per-900: #2d2b33;
  --pin-050: #fff8f9;
  --pin-100: #ffdde3;
  --pin-200: #ffc6d0;
  --pin-300: #ffa3b4;
  --pin-400: #ff7890;
  --pin-500: #ff385c;
  --pin-600: #d62f4d;
  --pin-700: #b32741;
  --pin-800: #912034;
  --pin-900: #56131f;
  --red-050: #fff9f7;
  --red-100: #ffe2dc;
  --red-200: #ffc3ba;
  --red-300: #ffa59a;
  --red-400: #ff7972;
  --red-500: #ea5052;
  --red-600: #cb464a;
  --red-700: #b03e42;
  --red-800: #96373b;
  --red-900: #813034;
  --whi-000: #ffffff;
  --whi-050: #f9f8f5;
  --whi-100: #e6e5e2;
  --whi-200: #d5d5d2;
  --whi-300: #bdbdba;
  --whi-400: #a4a3a1;
  --whi-500: #878685;
  --whi-600: #727271;
  --whi-700: #605f5e;
  --whi-800: #4c4c4b;
  --whi-900: #2c2c2c;

  /** Decision colours */
  --bac-tie1: var(--whi-000);
  --bac-tie2: var(--whi-050);
  --bor-err-bas: var(--red-600);
  --bor-err-mut: var(--red-200);
  --bor-neu-bas: var(--whi-200);
  --bor-neu-dis: var(--whi-300);
  --bor-neu-emp: var(--whi-600);
  --bor-neu-lig: var(--whi-000);
  --bor-neu-mut: var(--whi-100);
  --bor-pri-bas: var(--for-pri-bas);
  --bor-pri-dis: var(--for-pri-dis);
  --bor-pri-lig: var(--ind-100);
  --bor-pri-mut: var(--ind-300);
  --bor-suc-bas: var(--gre-600);
  --for-cau-lig: var(--ora-200);
  --for-err-lig: var(--red-200);
  --for-inf-lig: var(--blu-100);
  --for-neu-dis: var(--whi-200);
  --for-neu-inv: #0b051d;
  --for-neu-scr: #0b051d40;
  --for-neu-tie1: var(--bac-tie1);
  --for-neu-tie2: var(--bac-tie2);
  --for-neu-tie3: var(--whi-100);
  --for-neu-tra: #ffffff00;
  --for-pri-acc: var(--pin-500);
  --for-pri-bas: var(--ind-800);
  --for-pri-dis: #0b051d3d;
  --for-pri-emp: #0b051d;
  --for-pri-lig: var(--ind-050);
  --for-pri-mut: var(--ind-400);
  --for-pri-sub: var(--ind-100);
  --for-sec-buf_blu: #d2faff;
  --for-sec-ele_yel: #e4ff97;
  --for-sec-per_pin: #d6ccff;
  --for-sec-red_ora: #feab7c;
  --for-suc-bas: var(--gre-500);
  --for-suc-lig: var(--gre-100);
  --ico-cau-tie1: var(--tex-cau-tie1);
  --ico-def-tie1: var(--tex-def-tie1);
  --ico-def-tie2: var(--tex-def-tie2);
  --ico-def-tie3: var(--tex-def-tie3);
  --ico-err-tie1: var(--tex-err-tie1);
  --ico-err-tie2: var(--tex-err-tie2);
  --ico-inv-tie1: var(--tex-inv-tie1);
  --ico-inv-tie2: var(--tex-inv-tie2);
  --ico-inv-tie3: var(--tex-inv-tie3);
  --ico-suc-tie1: var(--tex-suc-tie1);
  --ico-suc-tie2: var(--tex-suc-tie2);
  --sta-acc-def: #ffffff00;
  --sta-acc-dis: var(--for-pri-dis);
  --sta-acc-err: #ffffff00;
  --sta-acc-hov: #ffffff00;
  --sta-acc-pre: #ffffff00;
  --sta-on_bac-def: #87868500;
  --sta-on_bac-dis: #87868500;
  --sta-on_bac-err: #87868500;
  --sta-on_bac-hov: #87868508;
  --sta-on_bac-pre: #87868514;
  --sta-on_inv-def: #ffffff00;
  --sta-on_inv-dis: #ffffff00;
  --sta-on_inv-err: #ffffff00;
  --sta-on_inv-hov: #ffffff3d;
  --sta-on_inv-pre: #ffffff7a;
  --sta-pri-def: #ffffff00;
  --sta-pri-dis: var(--for-pri-dis);
  --sta-pri-err: #ffffff00;
  --sta-pri-hov: #ffffff00;
  --sta-pri-pre: #ffffff00;
  --sta-sec-def: #6b34e000;
  --sta-sec-dis: #6b34e000;
  --sta-sec-err: #6b34e000;
  --sta-sec-hov: #6b34e014;
  --sta-sec-pre: #6b34e029;
  --sta-ter-def: #4c4c4b00;
  --sta-ter-dis: #4c4c4b00;
  --sta-ter-err: #4c4c4b00;
  --sta-ter-hov: #4c4c4b17;
  --sta-ter-pre: #4c4c4b29;
  --tex-act_lin-def: var(--ind-900);
  --tex-act_lin-hov: var(--ind-800);
  --tex-cau-tie1: var(--ora-800);
  --tex-def-tie1: #0b051d;
  --tex-def-tie2: #0b051dcc;
  --tex-def-tie3: #0b051d8c;
  --tex-err-tie1: var(--red-600);
  --tex-err-tie2: var(--red-800);
  --tex-inv-tie1: var(--whi-000);
  --tex-inv-tie2: #ffffffb8;
  --tex-inv-tie3: #ffffff8f;
  --tex-suc-tie1: var(--gre-800);
  --tex-suc-tie2: var(--gre-900);

  /** Border radius */
  --cor_rad-ful: 50%;
  --cor_rad-lg: 1rem; /* 16px */
  --cor_rad-md: 0.75rem; /* 12px */
  --cor_rad-sm: 0.625rem; /* 10px */
  --cor_rad-xs: 0.5rem; /* 8px */
  --cor_rad-2xs: 0.25rem; /* 4px */

  /** Elevation */
  --elevation-0dp: 0px 1px 2px #4c4c4b0f;
  --elevation-1dp: 0px 1px 5px #4c4c4b0f, 0px 1px 0px -1px #4c4c4b0a;
  --elevation-2dp: 0px 2px 4px #4c4c4b0f, 0px 1px 6px #4c4c4b08;
  --elevation-8dp: 0px 8px 12px -4px #4c4c4b1a, 0px 4px 6px -2px #4c4c4b0f;
  --elevation-12dp: 0px 12px 16px -4px #4c4c4b1a, 0px 4px 6px -2px #4c4c4b0a;
  --elevation-24dp: 0px 24px 40px -12px #4c4c4b33;

  /** default shading gradient */
  --shade-gradient-col1: #6b34e029;
  --shade-gradient-col2: #6b34e000;

  --gradient-product-placeholder: linear-gradient(
    180deg,
    rgba(199, 188, 172, 1) 0%,
    rgba(199, 188, 172, 1) 30%,
    rgba(162, 155, 141, 1) 65%,
    rgba(172, 170, 161, 1) 66%,
    rgba(179, 175, 165, 1) 75%,
    rgba(179, 175, 165, 1) 100%
  );

  /** Legacy colours, please remove at earliest convenience */
  --color-neutral-000: #ffffff;
  --color-neutral-050: #f8fbfe;
  --color-neutral-075: #f4f4f4;
  --color-neutral-100: #ebf2f5;
  --color-neutral-150: #e0e0e0;
  --color-neutral-200: #dce4e9;
  --color-neutral-300: #ced8dd;
  --color-neutral-400: #aebdc5;
  --color-neutral-500: #96a5af;
  --color-neutral-600: #6b7e8c;
  --color-neutral-700: #4d6276;
  --color-neutral-800: #2d415e;
  --color-neutral-900: #1e3557;

  --color-uiPrimary-000: #000000;
  --color-uiPrimary-025: #f8f7fd;
  --color-uiPrimary-050: #f3f1ff;
  --color-uiPrimary-100: #ebe9ff;
  --color-uiPrimary-200: #e2dfff;
  --color-uiPrimary-300: #9e9dcf;
  --color-uiPrimary-400: #8988c9;
  --color-uiPrimary-500: #6f6e89;
  --color-uiPrimary-600: #332e69;
  --color-uiPrimary-700: #28244b;
  --color-uiPrimary-800: #150d2c;
  --color-uiPrimary-900: #100b34;

  --color-brandPrimary-050: #ff6e82;
  --color-brandPrimary-100: #e83657;
  --color-brandPrimary-300: #b0002e;

  --color-sentimental-info-050: #f8f5ff;
  --color-sentimental-info-100: #efebff;
  --color-sentimental-info-200: #dcdbff;
  --color-sentimental-info-600: #4532b4;
  --color-sentimental-info-700: #2e1590;
  --color-sentimental-info-800: #26136f;

  --color-sentimental-success-050: #f2ffff;
  --color-sentimental-success-100: #d7fffd;
  --color-sentimental-success-200: #cbf8f5;
  --color-sentimental-success-500: #127a81;
  --color-sentimental-success-600: #179da6;
  --color-sentimental-success-700: #0b6166;
  --color-sentimental-success-800: #05484d;

  --color-sentimental-caution-100: #ffcfb5;
  --color-sentimental-caution-200: #ffb68d;
  --color-sentimental-caution-800: #903807;

  --color-sentimental-error-050: #ffe8eb;
  --color-sentimental-error-100: #ffe2e7;
  --color-sentimental-error-600: #da0027;
  --color-sentimental-error-700: #aa122d;
  --color-sentimental-error-800: #820624;

  --color-opacity-light-highEmphasis: rgba(255, 255, 255, 0.92);
  --color-opacity-light-mediumEmphasis: rgba(255, 255, 255, 0.65);
  --color-opacity-light-lowEmphasis: rgba(255, 255, 255, 0.43);
  --color-opacity-light-veryLowEmphasis: rgba(255, 255, 255, 0.3);

  --color-overlay: rgba(21, 13, 44, 0.15);

  --font-default: var(--font-armin), sans-serif;
  --font-display: var(--font-carmatitle), sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  color: var(--tex-def-tie1);
  background-color: var(--bac-tie1);
  font-family: var(--font-default);
  text-wrap-style: pretty;
}

body {
  overflow-x: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

body.lockedMdDown {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/** Utility class for screen-reader only text */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.white {
  color: white !important;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

/* Adyen hidden label */
.CardInput-module_card-input__icon__2Iaf5 {
  display: none;
}

/* Adyen hidden label */
.adyen-checkout__card__cvc__hint {
  display: none;
}

/* Adyen hidden label */
.adyen-checkout__label__text {
  display: none !important;
}
/* Adyen styles */
.adyen-checkout__loading-input__form {
  margin-top: 20px;
  padding: 0 20px;
}

body .adyen-checkout__input,
body .adyen-checkout__input:required {
  border: 1px solid #ebf2f5;
  border-radius: 7px;
  box-shadow:
    0 2px 1px -1px rgb(62 60 94 / 10%),
    0 3px 3px -2px rgb(150 147 189 / 40%);
  box-sizing: border-box;
  margin-top: 0;
  padding: 7px 20px;
  height: 56px;
}

body .adyen-checkout__input:hover {
  border: none;
  box-shadow:
    0 2px 1px -1px rgb(62 60 94 / 10%),
    0 3px 3px -2px rgb(150 147 189 / 40%),
    0 0 0 2px #100b34;
}

body .adyen-checkout__input.adyen-checkout__input--error,
body .adyen-checkout__input.adyen-checkout__input--invalid {
  box-shadow:
    0 2px 1px -1px rgb(62 60 94 / 10%),
    0 3px 3px -2px rgb(150 147 189 / 40%),
    0 0 0 2px #da0027;
  border: none;
}

body .adyen-checkout__label--focused .adyen-checkout__input,
body .adyen-checkout__label--focused .adyen-checkout__input:hover {
  border-radius: 7px;
  box-shadow:
    0 2px 1px -1px rgb(62 60 94 / 10%),
    0 3px 3px -2px rgb(150 147 189 / 40%),
    0 0 0 4px #100b34;
  border: none;
}

body .adyen-checkout__card__holderName__input::placeholder {
  font-size: 17px;
  font-weight: bold;
  line-height: normal;
  top: 10px;
  color: rgba(21, 13, 44, 0.701961);
}

body .adyen-checkout__card__holderName__input {
  padding-top: 8px;
}

body .adyen-checkout__input:placeholder {
  color: black;
  opacity: 1;
}

.input-field:placeholder-shown {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

/* Adyen image optimum size  */
.no-fees img {
  width: 35px !important;
  height: 22px !important;
}

/* Adyen text error */
.adyen-checkout__error-text {
  display: none !important;
}

.new-adyen-checkout__error-text {
  align-items: 'center';
  color: '#d10244';
  display: 'flex';
  font-size: '0.75em';
  font-weight: 400;
  margin-top: 4px;
}

.bodyBgColorView360 {
  background-color: #100b34;
}

.bodyBgColorOther {
  background-color: #28244b;
}

iframe#launcher {
  bottom: 25px !important;
  right: 25px !important;
  z-index: 100 !important;
}

p {
  margin: 0;
}

/* Prettier line breaks for text and headings */
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
li {
  text-wrap: pretty;
}

div,
section,
header,
footer,
blockquote {
  box-sizing: border-box;
}

/* Livechat widget z-index */
#chat-widget-container {
  z-index: var(--zIndex-livechat) !important;
}

/* Livechat interaction with car comparison banner */
body:has(.carComparisonBanner) #chat-widget-container {
  bottom: 3.5rem !important;
}
body:has(.carComparisonBanner.carComparisonBanner--expanded) #chat-widget-container {
  display: none !important;
}

/* style for custom font wrappers */
.c-fonts_wrapper {
  --font-default: var(--font-armin), sans-serif;
  --font-display: var(--font-carmatitle), sans-serif;
  font-family: var(--font-default);
}

/* style for NProgress */
#nprogress .peg {
  box-shadow: none !important;
}

/* Pixel trackers shifting the body */
body > img {
  position: absolute;
}

@media only screen and (min-width: 960px) {
  body.lockedMdDown {
    overflow: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
}
