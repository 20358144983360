@use 'sass:list';
@use 'sass:map';
@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

// ********************************************************* //
//                                                           //
// Set of helper classes that can be used across the website //
//                                                           //
// ********************************************************* //

////////////////////////////////////////////////////////////////
// Typography
//

// Font weights
$fontWeights: (
  normal: 400,
  semibold: 600,
  bold: 800,
  ultrabold: 900,
);
@each $weight, $value in $fontWeights {
  .c-fw-#{$weight} {
    font-weight: $value;
  }
}

// Font sizes
$fontSizes: (
  d1: (
    xs: (
      48,
      40,
    ),
    sm: (
      66,
      56,
    ),
    lg: (
      76,
      65,
    ),
  ),
  d2: (
    xs: (
      37,
      32,
    ),
    sm: (
      57,
      45,
    ),
    lg: (
      61,
      52,
    ),
  ),
  h1: (
    xs: (
      48,
      46,
    ),
    sm: (
      66,
      62,
    ),
    lg: (
      76,
      72,
    ),
  ),
  h2: (
    xs: (
      37,
      40,
    ),
    sm: (
      53,
      56,
    ),
    lg: (
      61,
      64,
    ),
  ),
  h3: (
    xs: (
      32,
      28,
    ),
    sm: (
      40,
      48,
    ),
    lg: (
      44,
      48,
    ),
  ),
  h4: (
    xs: (
      26,
      28,
    ),
    sm: (
      28,
      32,
    ),
    lg: (
      32,
      40,
    ),
  ),
  h5: (
    xs: (
      21,
      28,
    ),
    sm: (
      24,
      28,
    ),
    lg: (
      26,
      32,
    ),
  ),
  h6: (
    xs: (
      19,
      24,
    ),
    sm: (
      20,
      28,
    ),
    lg: (
      20,
      28,
    ),
  ),
  body1: (
    xs: (
      17,
      24,
    ),
    sm: (
      17,
      24,
    ),
    lg: (
      17,
      24,
    ),
  ),
  body2: (
    xs: (
      15,
      20,
    ),
    sm: (
      15,
      20,
    ),
    lg: (
      15,
      20,
    ),
  ),
  caption: (
    xs: (
      14,
      18,
    ),
    sm: (
      14,
      18,
    ),
    lg: (
      14,
      18,
    ),
  ),
  caption2: (
    xs: (
      12,
      16,
    ),
    sm: (
      12,
      16,
    ),
    lg: (
      12,
      16,
    ),
  ),
  overline: (
    xs: (
      12,
      16,
    ),
    sm: (
      12,
      16,
    ),
    lg: (
      12,
      16,
    ),
  ),
);

@each $fontSize, $value in $fontSizes {
  .c-fs-#{$fontSize} {
    $sizes: map.get($value, 'xs');
    @include fontSize(list.nth($sizes, 1), list.nth($sizes, 2));
    margin: 0;

    @include sm {
      $sizes: map.get($value, 'sm');
      @include fontSize(list.nth($sizes, 1), list.nth($sizes, 2));
    }

    @include lg {
      $sizes: map.get($value, 'lg');
      @include fontSize(list.nth($sizes, 1), list.nth($sizes, 2));
    }
  }
}

.c-fs-d1,
.c-fs-d2 {
  font-family: var(--font-display);
  font-weight: 900;
}
.c-fs-caption,
.c-fs-caption2 {
  letter-spacing: pxToRem(0.2);
}
.c-fs-overline {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.1em;
}

////////////////////////////////////////////////////////////////
// Sections
//
.c-se {
  @include sectionVerticalSpacing();

  &--default {
    @include sectionHorizontalSpacing();
  }
  &--small {
    @include sectionHorizontalSpacing($max-width-section-sm);
  }
  &--contentHub {
    @include sectionContentHub();
  }
  &--article {
    @include sectionContentHubArticle();
  }

  &--white {
    @include fullWidthBackgroundColor(var(--bac-tie1));
  }

  &--sand {
    @include fullWidthBackgroundColor(var(--bac-tie2));
  }
}

////////////////////////////////////////////////////////////////
// Container queries
//
.c-queries {
  &--normal {
    container-type: normal;
  }

  &--size {
    container-type: size;
  }

  &--inline {
    container-type: inline-size;
  }
}
